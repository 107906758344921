:root {
    /* Basic Variables */
    --clasicInput: #f7f7f7;
    --blackBackground1: rgb(26, 26, 26);

    /* Devis Variables */
    --devisInput: rgb(241, 230, 209);
    --blackBackground2: rgb(48, 45, 45);
    --emptyLine: #b8b8b8;
    --sousTotal: #ffa600;
}