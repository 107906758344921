.projectHome{
	/* overflow-y: scroll; */
}

.shadow {
	box-shadow: 0px 0px 5px 1px black;
}

.filterElement{
	width: 250px;
}

.leftPart {
	width: calc((100% / 3) * 2);
}

.rightPart {
	width: calc(100% / 3);
}

.projectList {
	overflow-y: auto;
	min-height: 100vh;
}

.urgentDates {
	min-height: 100vh;
	/* overflow-y: auto; */
}

.urgent {
	background-color: #fad7d7;
}

.favori {
	background-color: antiquewhite;
}

.title {
	text-decoration: 'none';
}

.content {
	padding: 0;
}

.subtask {
	margin-bottom: 10px;
}

.subtaskText {
	width: 35%;
	white-space: nowrap;
 	overflow: hidden;
  	text-overflow: ellipsis;
}

.crossed {
	text-decoration: line-through;
}

.urgentProject {
	
	background-color: cadetblue;
}