body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#projectList {
    width: 90%;
    margin-left: 5%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project{
    width: 500px;
    margin: 25px;
    overflow: hidden;
}



.collapsible {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.collapsible p{
  margin: 10px 0px;
  width: 42%;
}

.collapsible div {
  margin: 10px 0px;
}

.accordion-button{
    padding: 0 10px;
    display: flex;
    align-self: center;
}

.task-accordion{
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
}

  .content {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .taskTable{
    width: 100%;
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: space-around;
  }

  .taskTable p{
    width: 25%;
    margin: auto;
  }
  .taskTable button{
    width: fit-content;
    height: 30px;
    font-size: 0.7em;
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }

  .content p{
    width: 40%;
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content button{
    width: 10%;
    margin-left: 5%;
    text-align: center;
  }

  .dot{
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .red {
    background-color: red;
  }

  .orange {
    background-color: orange;
  }

  .green {
    background-color: green;
  }

  .crossLine p{
    text-decoration: line-through;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 0;
  }

  .flexHorizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  
  .flexHorizontal p{
    margin-bottom: 0px;
  }

  .addingProject{
    width: 20%;
    margin: auto;
  }

  .projectTitle{
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px 0;
    text-align: center;
    display: flex;
    font-size: 1em;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

  .textCondense {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .projectClient {
    width: 50%;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .projectBtn {
    width: 20%;
    font-size: 0.7em;
    overflow: hidden;
    padding: 5px 10px;
    margin: auto;   
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .cardHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cardHeader p{
    margin: auto 0 ;
  }

  .projectInfoText{
    margin-top: -10px;
  }

  .taskSelectable td{
    padding:3%;
    overflow-y: hidden;
    
  }
  .taskSelectable .taskBtn{
    width: 30%;
    
  }

  .taskSelectable .btn{
    padding: 6%;
    margin: -20% 0%;
  }

  .datePicker{
    width: 35%;
  }

  .accordion-body{
    padding: 0px;
    margin: 0px;
  }

  .folderPathSelector{
    display: flex;
    justify-content: space-between;
  }

  .folderPathSelector button{
    width: 25%;
  }

  .folderPathSelector p{
    width: 70%;
    margin: auto;
    font-size: .8em;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }

  .deleteIcon{
    width: 40px;
    margin-left: auto;
    padding: 5px;
  }

  .textArea{
    width: 95%;
    margin-left: 2.5%;
    margin-right: auto;
  }
  .validateBriefBtn{
    width: 20%;
    margin-left: 40%;
  }

  .backdropElevate{
    z-index: 2000;
  }

  .centerDiv{
    display: flex;
    justify-content: center;
  }

  .p-20{
    padding: 0 20px;
  }

  span{
    font-size: 0.8em;
  }

  .filterBar {
    width: 100%;
    height: 70px;
    display: inline-block;
  }

  .taskAccordion{
    width: 100%;
    border: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .taskAccordion p {
    width: 45%;
    margin-bottom: 0;
  }

  .subtaskAccordion {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .subtaskAccordion p {
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subtask-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 10pt;
  }

  .subtask-line p{
    width: 38%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
    padding-top: 5px;
  }

  .subtask-line Button{
    margin: 0 auto;
    padding: 0 5px;
    height: 30px;
    width: 60px;
    margin: 10px 0;
  }

/* .form-input{
  background-color: white;
  text-align: center;
  color: black;
} */

.subtask-title button{
  font-size: 14pt;
  padding: 15px;
}

.subtask-info th{
  font-size: 12px;
  padding: 10px 15px;
}